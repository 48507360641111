<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="1000px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ model.id ? 'Edición' : 'Creación' }} Feria
          </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 col-md-6 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nombre"
                  vid="name"
                  rules="required|max:80"
                >
                  <v-text-field
                    v-model="model.name"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Nombre"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-6 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Año"
                  vid="year"
                  rules="required|max:4"
                >
                  <v-text-field
                    v-model="model.year"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Año"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-6 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Fecha inicio"
                  vid="startDate"
                  rules="required"
                >
                  <date-picker
                    label="Fecha de inicio"
                    v-model="model.startDate"
                    :hide-details="false"
                    :errors="errors"
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-6 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Fecha fin"
                  vid="endDate"
                  rules="required"
                >
                  <date-picker
                    label="Fecha de fin"
                    v-model="model.endDate"
                    :hide-details="false"
                    :errors="errors"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 py-0">
                <validation-provider
                  vid="description"
                  v-slot="{ errors }"
                  name="Descripción"
                  rules="min:0|max:600"
                >
                  <v-textarea
                    counter="600"
                    outlined
                    type="text"
                    rows="2"
                    :error-messages="errors"
                    clear-icon="mdi-close-circle"
                    label="Escribe una breve descripción de la feria"
                    v-model="model.description"
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-6 py-0">
                <file-chooser
                  ref="fileChooser"
                  @input="onLoadPlaneImageUrl"
                  :options="{
                    height: '190px',
                    description:
                      'Haz clic aquí para cargar el plano general de la feria',
                    regex: /\.(jpg|jpeg|png|gif)$/,
                    error: 'Solo se permiten imagenes',
                    fileSize: 2,
                  }"
                >
                </file-chooser>
                <small>El tamaño que debe tener la imagen general: 1200 x 678 px.</small>
              </div>
              <div class="col-12 col-md-6 py-0">
                <file-chooser
                  ref="fileChooser2"
                  @input="onLoadPinIconUrl"
                  :options="{
                    height: '190px',
                    description:
                      'Haz clic aquí para cargar el pin del plano general de la feria',
                    regex: /\.(jpg|jpeg|png|gif)$/,
                    error: 'Solo se permiten imagenes',
                    fileSize: 2,
                  }"
                >
                </file-chooser>
              </div>
              <div class="col-12 py-0 pl-4">
                <v-switch v-model="model.isActive" label="¿Activa?"/>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveFair">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import FileChooser from '@/components/file-chooser/FileChooser'
import { FAIRS_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import DatePicker from '@/components/date-picker/DatePicker'

export default {
  name: 'FairModal',
  components: {
    DatePicker,
    FileChooser
  },
  props: {
    fair: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        description: null,
        endDate: null,
        isActive: true,
        name: null,
        pinIconUrl: null,
        planeImageUrl: null,
        startDate: null,
        year: null
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        description: null,
        endDate: null,
        isActive: null,
        name: null,
        pinIconUrl: null,
        planeImageUrl: null,
        startDate: null,
        year: null
      }
      this.$refs.fileChooser.setError(null)
      this.$refs.fileChooser2.setError(null)
    },
    onLoadPinIconUrl (file) {
      this.model.pinIconUrl = file
    },
    onLoadPlaneImageUrl (file) {
      this.model.planeImageUrl = file
    },
    open (fair) {
      this.isActive = true
      this.$nextTick(() => {
        if (fair) {
          this.model = fair
          this.$refs.fileChooser.setImage(fair.planeImageUrl)
          this.$refs.fileChooser2.setImage(fair.pinIconUrl)
        } else {
          this.$refs.fileChooser.setImage(null)
          this.$refs.fileChooser2.setImage(null)
        }
      })
    },
    saveFair () {
      if (!this.model.planeImageUrl) {
        this.$refs.fileChooser.setError('El plano general es requerido')
      }
      if (!this.model.pinIconUrl) {
        this.$refs.fileChooser2.setError('El pin es requerido')
      }
      this.executeWithFormValidation(() => {
        if (this.model.planeImageUrl && this.model.pinIconUrl) {
          const formData = this.getFormData()
          formData.append('planeImageUrl', this.model.planeImageUrl)
          formData.append('pinIconUrl', this.model.pinIconUrl)
          this.create(FAIRS_URL, formData).then(() => {
            this.close()
            this.$emit('onClose')
          })
        }
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
