const headers = [
  {
    text: 'Nombre',
    value: 'name',
    sortable: false
  },
  {
    text: 'Año',
    value: 'year',
    sortable: false
  },
  {
    text: 'Fecha de inicio',
    value: 'startDate',
    sortable: false
  },
  {
    text: 'Fecha de fin',
    value: 'endDate',
    sortable: false
  },
  {
    text: '¿Activa?',
    value: 'isActive',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '25%'
  }
]
export default headers
